import { BasicEvent } from "@h4x/common";
import type { Subscriber, Unsubscriber } from "svelte/store";
import { HewSync } from "./HewSync";
import type { SubscriptionEvent } from "./HewSyncSocket";

export interface InternalHewSyncClass<T> {
	getKey(data: any): string;
	// from(data: any): T;
	apply(data: any): void;
	cache: Map<string, T>;
	onSubscriptionEvent: BasicEvent<(data: SubscriptionEvent) => void>;
	initSubscriptions: () => void;
	new (data: any): T;
	parse(data: any): any;
	get(data: any): T;
}

export interface InternalHewSyncType {
	loading: boolean;
	updating: boolean;
	removed: boolean;

	onUpdate: BasicEvent<(item: any) => void>;
	onRemove: BasicEvent<(item: any) => void>;

	load(): Promise<any>;

	detach?(): void;
}

export class HewSyncType {
	public static type: string;
	public readonly type: string;
	protected constructor(x: void) {
		if ((x as any) !== Symbol.for("HewSyncType::Internal::Create")) {
			throw new Error("Cannot create");
		}
	}

	public readonly onUpdate = new BasicEvent<(item: this) => void>();
	public readonly onRemove = new BasicEvent<(item: this) => void>();
	public readonly onReplaced = new BasicEvent<(item: this, target: any) => void>();

	public readonly loading: boolean = true;
	public readonly error: Error | undefined = undefined;
	public readonly updating = false;
	public readonly removed = false;

	public readonly authorized: boolean = true;

	private loadingPromise: Promise<this> | undefined;
	private loadingPromiseResolve: ((value: this) => void) | undefined;

	protected apply(data: any): void {
		throw new Error("Not implemented");
	}

	protected defaults(): any {
		throw new Error("Not implemented");
	}

	protected reset(): void {
		this.apply(this.defaults());
	}

	protected internalRemove() {
		(this.removed as any) = true;
		(this.loading as any) = false;
		(this.updating as any) = false;
		this.onRemove.execute(this);
		this.onUpdate.execute(this);
	}

	protected async fetch(data: { [K: string]: any }) {
		let type = this.constructor as typeof HewSyncType;

		let requiredPermission = HewSync.PermissionsMap[type.name]!;
		let organization = data["organization"]?.value as string;

		if (organization && requiredPermission) {
			let permission = HewSync.permissionManager.check(requiredPermission, organization);
			console.debug(
				"Permission check fetch",
				type.name,
				requiredPermission,
				permission,
				organization,
				HewSync.permissionManager
			);

			HewSync.permissionManager.onUpdate.addCallback(() => {
				let updatedPermission = HewSync.permissionManager.check(requiredPermission, organization);
				if (updatedPermission !== permission) {
					console.log(
						"Permission recheck fetch",
						type.name,
						requiredPermission,
						updatedPermission,
						organization,
						HewSync.permissionManager
					);
					if (updatedPermission === false) {
						(this.authorized as any) = false;
						(this.loading as any) = false;
						this.reset();
						this.onUpdate.execute(this);
					} else {
						(this.authorized as any) = true;
						(this.loading as any) = true;
						this.internalFetch(type, data);
						this.onUpdate.execute(this);
					}
					permission = updatedPermission;
				}
			});

			if (permission === false) {
				(this.authorized as any) = false;
				this.onUpdate.execute(this);
				return;
			}
		}

		await this.internalFetch(type, data);
	}

	private async internalFetch(type: typeof HewSyncType, data: { [K: string]: any }) {
		try {
			let result = await HewSync.get(type, data, this).execute();
			console.debug("internalFetch", result);
			if (result === undefined) {
				this.setErrored(new Error("Not found"));
			} else {
				// this.apply(result);
				this.setLoaded();
			}
		} catch (e) {
			if (e instanceof Error && e.message === "You are not authorized to make this call.") {
				console.warn(type.name, "Error getting item", e);
				console.warn(e.message);
				(this.authorized as any) = false;
				this.onUpdate.execute(this);
			} else {
				throw e;
			}
		}
	}

	protected setLoaded() {
		(this.loading as any) = false;
		if (this.loadingPromiseResolve !== undefined) {
			this.loadingPromiseResolve(this);
		}
		this.onUpdate.execute(this);
	}

	protected setErrored(error: Error) {
		(this.error as any) = error;
		(this.loading as any) = false;
		if (this.loadingPromiseResolve !== undefined) {
			this.loadingPromiseResolve(this);
		}
		this.onUpdate.execute(this);
	}

	public load() {
		if (this.loading) {
			if (this.loadingPromise === undefined) {
				this.loadingPromise = new Promise((resolve) => {
					this.loadingPromiseResolve = resolve;
				});
			}
			return this.loadingPromise;
		}
		return Promise.resolve(this);
	}

	public setUpdating(value: boolean) {
		(this.updating as any) = value;
		this.onUpdate.execute(this);
	}

	public subscribe(run: Subscriber<this>): Unsubscriber {
		let ref = this.onUpdate.addCallback(() => {
			run(this);
		});
		let ref2 = this.onRemove.addCallback(() => {
			run(this);
		});
		run(this);
		return () => {
			ref.remove();
			ref2.remove();
		};
	}

	protected replace(item: this) {
		this.onReplaced.execute(this, item);
	}
}
