import { normalizeCode } from "./HewSyncUtils";
import { HewSyncType } from "./HewSyncType";
import { ErrorSessionResponse, HewSyncSocket, SessionResponse, SubscriptionEvent } from "./HewSyncSocket";
import { BasicEvent } from "@h4x/common";
import { HewSyncID, HewSyncPath } from "./IDs";

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type Props<T, K extends keyof T> = Writeable<Pick<T, K>>;
export type PartialProps<T, K extends keyof T> = Partial<Props<T, K>>;

export type HewSyncTypeClass = {
	// readonly primaryIndex: any;
	type: string;
};

type TypeConfig = {
	readonly type: string;
	readonly scope: string;
	readonly name: string;
	readonly table: string;
	readonly prefix?: string;
	readonly parent?: typeof HewSyncType;
	readonly attachment?: [any, any];
};

class DataEntry {
	constructor(public readonly name: string, protected target: typeof HewSyncType) {}
	public is(target: typeof HewSyncType) {
		return this.target === target;
	}
}

type DataFieldConfig = {
	readonly type?: string;
	readonly required?: boolean;
	readonly reference?: string;
	readonly default?: string;
	readonly input?: string;
	readonly prefix?: string;
	readonly createOnly?: true;
	readonly generated?: string;
};

class DataField extends DataEntry {
	constructor(name: string, target: typeof HewSyncType, private config: DataFieldConfig) {
		super(name, target);
	}

	public asPrefix() {
		return this.config.prefix;
	}

	public get type() {
		return this.config.type;
	}

	public get input() {
		return this.config.input ?? this.config.type;
	}
}

export class HewSyncTypeConfig {
	public fields: { [K: string]: DataField } = {};
	public type: TypeConfig;
	constructor(public readonly target: typeof HewSyncType & HewSyncTypeClass) {}

	get fieldsList() {
		return Object.values(this.fields);
	}

	public get name() {
		let scope = this.type.scope[0]!.toUpperCase() + this.type.scope.substring(1);
		let name = scope + "_" + this.type.name;
		return name;
	}
}

// custom types
export namespace HewSync {
	export const PermissionsMap: { [K: string]: string } = {
		ProjectInstance: "view:synapse",
		SynapseFile: "view:synapse",
		SynapseFolder: "view:synapse",
		SynapseSession: "view:synapse",
		UserAsset: "view:organization",
		UserEntity: "view:api",
		UserEntityRole: "view:api",
		UserEntityToken: "view:api",
		UserGroup: "view:user",
		UserGroupRole: "view:user",
		UserInvite: "view:organization",
		UserInviteAccount: "view:organization",
		UserInviteEmail: "view:organization",
		UserMember: "view:user",
		UserMemberGroup: "view:user",
		UserMemberRole: "view:user",
		UserOrganization: "view:organization",
		UserRole: "view:user"
	};

	export type SessionAttachment = {
		type: "File" | "Folder";
		value: string[];
	};

	export type SynapseMessage = {
		readonly id: string;
		readonly message: {
			readonly content: string;
			readonly createdAt: number;
			readonly createdBy: string;
		};
		readonly response: {
			readonly content: string;
			readonly createdAt: number;
			// ???
		} | null;
		readonly config: {
			OpenAI: {
				runID: string;
				status: "in_progress";
			};
		};
		readonly attachments: SessionAttachment[];
	};

	export type Permission = {
		readonly actions: string[];
		readonly path: string;
		readonly scope: string;
	};
}

export namespace HewSync {
	export let auth = undefined as string | undefined;
	export let id_token = undefined as string | undefined;
	let authResolve: ((value: string) => void) | undefined;

	export const onConnectionUpdate = new BasicEvent<() => void>();

	export let authPromise = new Promise<string>((resolve) => {
		authResolve = resolve;
	});

	export let socket = new HewSyncSocket();

	export function subscribe(config: HewSyncTypeConfig, callback: (data: SubscriptionEvent) => void) {
		let name = config.type.name;
		socket.subscribe(name, callback);
	}

	export let accountID: string = undefined!;
	let connectionUrl: string = undefined!;

	export async function onAuth(
		token: string,
		baseUrl?: string,
		id_token: string | undefined = undefined,
		force = false
	) {
		console.debug("[HewSync] onAuth", token, baseUrl, id_token, accountID);

		if (!token.startsWith("DevPanel ") && !token.startsWith("LOGIN-AS ")) {
			token = `Bearer ${token}`;
		}

		if (auth === token && accountID) {
			return accountID;
		}
		auth = token;

		try {
			await fetchSession(baseUrl!, token, id_token, force);
			await socket.connect(auth, connectionUrl, id_token);

			authResolve?.(token);
		} catch (error) {
			onConnectionUpdate.execute();
			console.debug("[HewSync] Catch Error onAuth", error);
		}

		return accountID;
	}

	async function fetchSession(baseUrl: string, token: string, id_token: string | undefined, force = false) {
		try {
			const data = await fetch(`${baseUrl}/hewsync/session`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `${token}`,
					...(id_token ? { "X-Id-Token": id_token } : {})
				},
				body: JSON.stringify(force ? { confirm: true } : {})
			});

			const response = (await data.json()) as SessionResponse;

			if (response.error) {
				throw { hewsync: response };
			}

			if (response.data) {
				accountID = response.data.accountID;

				connectionUrl = `${baseUrl}${response.data.url}`;
			}
		} catch (error: any) {
			if (error?.hewsync) {
				console.debug("[HewSyncSocket] Error fetching session", error.hewsync);
				socket.connectionState.set("error");

				socket.error.set(error.hewsync as ErrorSessionResponse);
				throw error;
			}

			socket.connectionState.set("connection-error");
			socket.error.set({ error: "Connection Error" });
			throw error;
		}
	}

	let override: string | ((query: any) => Promise<any>) | undefined = undefined;
	export function configure(newURL: string | ((query: any) => Promise<any>)) {
		override = newURL;
		authResolve?.("local");
	}

	export class PermissionManager {
		private permissions: Map<string, HewSync.Permission[]> = new Map();
		private initialized = false;
		private debugMode = false;
		private side: "admin" | "user" = undefined!;
		public readonly onUpdate = new BasicEvent<() => void>();
		constructor() {}

		public setSide(side: "admin" | "user") {
			this.side = side;
		}

		public setPermissions(permissions: { [K: string]: HewSync.Permission[] }) {
			this.permissions.clear();
			for (let [key, value] of Object.entries(permissions)) {
				this.permissions.set(key, value);
			}

			this.initialized = true;
			this.onUpdate.execute();
		}

		public setDebugMode(value: boolean) {
			this.debugMode = value;
		}

		public check(action: string, organization: string) {
			if (!this.initialized) {
				return true;
			}

			if (this.debugMode && JSON.parse(localStorage.getItem("HEWMEN_PERM_SKIP") || "0") === "1") {
				return true;
			}

			if (action === "view:organization") {
				return true;
			}

			let permissions = this.permissions.get(this.side === "admin" ? "0AdminAdminAdmin" : organization);
			if (permissions === undefined) {
				return false;
			}

			return permissions.some((x) => x.actions.includes(action) || x.actions.includes("*"));
		}
	}

	export let permissionManager = new PermissionManager();

	export class Timestamp {
		constructor(public readonly value: string) {
			if (value === undefined || value === null) {
				throw new Error("Invalid timestamp");
			}
			try {
				this.value = new Date(value).toISOString();
			} catch (e) {
				throw new Error("Invalid timestamp, failed to parse");
			}
		}

		public asDate() {
			return new Date(this.value);
		}

		public asTime() {
			return this.value;
		}

		public getTime() {
			return new Date(this.value).getTime();
		}

		public toString() {
			return this.value;
		}
	}

	export const types = new Map<typeof HewSyncType, HewSyncTypeConfig>();

	function getTypeData(target: typeof HewSyncType) {
		let data = types.get(target);
		if (!data) {
			data = new HewSyncTypeConfig(target as typeof HewSyncType & HewSyncTypeClass);
			types.set(target, data);
		}
		return data;
	}

	export function getFullTypeData(target: Function) {
		return getTypeData(target as typeof HewSyncType);
	}

	export function Field<T extends HewSyncType>(config: DataFieldConfig) {
		return function (target: T, propertyKey: string) {
			let cls = target.constructor as typeof HewSyncType;
			let data = getTypeData(cls);
			data.fields[propertyKey] = new DataField(propertyKey, cls, config);
		};
	}

	export function Type<T>(config: TypeConfig) {
		return function (target: T) {
			let data = getTypeData(target as typeof HewSyncType);
			data.type = config;
		};
	}

	type InternalType<T> = {
		getKey(data: any): string;
		parse(data: any): T;
		cache: Map<string, T & { apply: (data: any) => void; replace: (data: any) => void }>;
		onSubscriptionEvent: BasicEvent<(data: SubscriptionEvent) => void>;
	};

	function handleEvent<K extends typeof HewSyncType>(type: K & InternalType<HewSyncType>, data: SubscriptionEvent) {
		if (data.$event === "Created") {
			let created = type.parse(data);
			type.onSubscriptionEvent.execute({
				$event: data.$event,
				...created
			});
		} else if (data.$event === "Updated") {
			let updated = type.parse(data);
			let key = type.getKey(updated);
			let item = type.cache.get(key);
			if (item !== undefined) {
				item.apply(updated);
			}

			type.onSubscriptionEvent.execute({
				$event: data.$event,
				...updated
			});
		} else if (data.$event === "KeyUpdated") {
			let old = type.parse(data.old);
			let updated = type.parse(data.updated);

			let key = type.getKey(old);
			let item = type.cache.get(key);
			if (item !== undefined) {
				item.replace(updated);
			}

			type.onSubscriptionEvent.execute({
				$event: data.$event,
				old: old,
				updated: updated
			});
		} else if (data.$event === "Removed") {
			let ids = type.parse(data);
			let key = type.getKey(ids);
			let item = type.cache.get(key);
			if (item !== undefined) {
				(item as any).internalRemove();
			}

			type.onSubscriptionEvent.execute({
				$event: data.$event,
				...ids
			});
		} else {
			throw new Error("Not implemented");
		}
	}

	async function internalInitSubscriptions<K extends typeof HewSyncType>(type: K & InternalType<HewSyncType>) {
		await HewSync.subscribe(HewSync.getFullTypeData(type), (data: SubscriptionEvent) => handleEvent(type, data));
	}

	export async function initSubscriptions<K extends typeof HewSyncType>(
		type: K,
		_event: BasicEvent<(data: any) => void>
	) {
		await internalInitSubscriptions(type as K & InternalType<HewSyncType>);
	}

	/*class OldHewSyncListQuery<T> {
		constructor(
			public readonly config: HewSyncTypeConfig,
			public readonly type: string,
			public readonly text: string,
			public readonly variables: QueryVariable[]
		) {}

		public async execute(): Promise<{ items: T[]; nextToken?: string }> {
			let result = await executeQuery(this.type, this.text, this.variables);
			if (result.errors) {
				if (result.errors[0]!.message !== "You are not authorized to make this call.") {
					console.error(
						`[HewSync] Unable to execute operation\nOperation: ${this.text}\nVariables: ${JSON.stringify(
							this.variables,
							null,
							2
						)}\nErrors:`,
						result.errors
					);
				}
				throw new Error(result.errors[0]!.message);
			}

			let output = result.data.output as {
				items: unknown[];
				nextToken?: string;
			};
			if (typeof output === "object" && output && "items" in output && Array.isArray(output?.items)) {
				return { items: output.items.map((it) => it as T), nextToken: (output.nextToken as string) ?? undefined };
			} else {
				throw new Error("Invalid output");
			}
		}
	}


	export class OldHewSyncQuery<T> {
		constructor(
			public readonly config: HewSyncTypeConfig,
			public readonly target: HewSyncQueryType | true | false | undefined,
			public readonly type: string,
			public readonly text: string,
			public readonly variables: QueryVariable[]
		) {}

		public async execute(): Promise<T> {
			console.debug("[HewSyncQuery] Updating?:", this.target !== undefined);

			if (this.target !== undefined && this.target !== true && this.target !== false) {
				this.target.setUpdating(true);
			}
			let result = await executeQuery(this.type, this.text, this.variables);
			if (result.errors) {
				if (result.errors.length === 1 && result.errors[0]!.message === "Not found") {
					return undefined as T;
				}

				if (result.errors[0]!.message !== "You are not authorized to make this call.") {
					console.error(
						`[HewSync] Unable to execute operation\nOperation: ${this.text}\nVariables: ${JSON.stringify(
							this.variables,
							null,
							2
						)}\nErrors:`,
						result.errors
					);
				}
				throw new Error(result.errors[0]!.message);
			}
			let output = result.data.output as unknown;

			if (this.target === true || this.target === false) {
				let TargetClass = this.config.target as unknown as {
					from(data: any): HewSyncType;
					getKey(data: any): string;
					cache: Map<string, T>;
				};

				let key = TargetClass.getKey(output);
				output = TargetClass.from(output) as T;
				if (this.target === true) {
					if (!TargetClass.cache.has(key)) {
						TargetClass.cache.set(key, output);
					}
				}
				output.setLoaded();
			} else if (this.target !== undefined) {
				this.target.setUpdating(false);
				// return this.target as T;
			}
			return output as T;
		}
	}*/

	type HewSyncTypeInternal = HewSyncType & {
		apply: (data: any) => void;
		setUpdating: (value: boolean) => void;
		internalRemove: () => void;
		setLoaded: () => void;
	};

	type HewSyncTypeInternalType = {
		parse: (data: any) => any;
		// from(data: any): HewSyncType;
		getKey(data: any): string;
		cache: Map<string, HewSyncType>;

		new (data: any): HewSyncType;
	} & typeof HewSyncType;

	const Create = Symbol("HewSyncType::Internal::Create");
	const CreateWithCache = Symbol("HewSyncType::Internal::CreateWithCache");
	const List = Symbol("HewSyncType::Internal::List");

	export class HewSyncQuery<T extends HewSyncType, K = T> {
		constructor(
			public readonly config: HewSyncTypeConfig,
			public readonly query: any,
			public target: T | typeof CreateWithCache | typeof Create | typeof List | undefined
		) {}

		get internalTarget() {
			return this.target as any as HewSyncTypeInternal;
		}

		get InternalClass() {
			return this.config.target as unknown as HewSyncTypeInternalType;
		}

		async sendRequest() {
			let response;
			if (override instanceof Function) {
				response = await override(this.query);
			} else {
				response = await socket.request(this.query);
			}

			if (response.error) {
				throw new Error(JSON.stringify(response.error));
			}

			return response;
		}

		async execute(): Promise<K> {
			console.debug("execute", this.query);
			console.debug("target", this.target);
			if (this.target instanceof HewSyncType) {
				this.target.setUpdating(true);
				let response = await this.sendRequest();
				console.debug("response", response, this.target, this.query);
				let result = response.result;
				if (typeof result === "object" && result !== null && "$event" in result) {
					handleEvent(this.InternalClass as any, result);
				} else {
					if (response.result === null) {
						this.internalTarget.internalRemove();
						return undefined!;
					} else {
						let data = this.InternalClass.parse(response.result);
						console.debug("response applying", response.result, data, "to", this.target);
						this.internalTarget.apply(data);
						console.debug("response applied", JSON.stringify(this.target));
					}
				}
				this.target.setUpdating(false);
				return this.target as any as K;
			} else if (this.target === CreateWithCache || this.target === Create) {
				let response = await this.sendRequest();
				console.debug("response", response, this.target, this.query);
				// TODO handle errors
				// TODO handle get -> not found
				let result = response.result;
				let data = this.InternalClass.parse(result);

				if (this.target === CreateWithCache) {
					// cache
					let key = this.InternalClass.getKey(result);
					this.target = new this.InternalClass(data) as T;

					if (this.InternalClass.cache.has(key)) {
						console.error("Cache already has key", key);
					}
					this.InternalClass.cache.set(key, this.target);
				} else {
					// no cache
					this.target = new this.InternalClass(data) as T;
				}
				this.internalTarget.setLoaded();
				if (typeof result === "object" && "$event" in result) {
					handleEvent(this.InternalClass as any, result);
				}
				return this.target as any as K;
			} else if (this.target === List) {
				let response = await this.sendRequest();
				console.debug("response", response);
				let items = response.result.items.map((data: any) => {
					if (typeof data !== "object" || data === null) {
						return data;
					}
					let item = new this.InternalClass(this.InternalClass.parse(data));
					(item as any).setLoaded();
					return item;
				});

				return {
					items: items,
					nextToken: response.nextToken
				} as any as K;
			} else {
				let response = await this.sendRequest();
				console.debug("response", response);
				let result = response.result;
				if (typeof result === "object" && "$event" in result) {
					let type = result["$event"];
					let ret = undefined;
					if (type === "Created") {
						// TODO
						/*let data = this.InternalClass.parse(result);
						this.target = new this.InternalClass(data) as T;

						this.internalTarget.setLoaded();
						ret = this.target as any as K;*/
					} else if (type === "Updated") {
						// ???
					} else if (type === "KeyUpdated") {
						let updated = result.updated;
						// let type = updated.type; // TODO

						let data = this.InternalClass.parse(updated);
						this.target = new this.InternalClass(data) as T;

						this.internalTarget.setLoaded();
						ret = this.target as any as K;
					} else if (type === "Removed") {
						// ???
					} else {
						throw new Error("Not implemented");
					}

					handleEvent(this.InternalClass as any, result);

					if (ret !== undefined) {
						return ret;
					}
				}
				return result as any as K;
			}

			throw new Error("Not implemented");
		}
	}

	function toParams(inputs: { [K: string]: any }) {
		let params: { [K: string]: any } = {};

		for (let [key, value] of Object.entries(inputs)) {
			if (value instanceof Timestamp) {
				value = value.toString();
			} else if (value instanceof HewSyncID) {
				value = value.value;
			} else if (value instanceof HewSyncPath) {
				value = value.value;
			} else if (value instanceof Array) {
				value = value.map((x) => (x instanceof HewSyncID ? x.value : x));
			}
			params[key] = value;
		}
		return params;
	}

	export function get<V extends HewSyncType>(type: typeof HewSyncType, inputs: { [K: string]: any }, target?: V) {
		let config = getFullTypeData(type);

		let query = {
			type: config.type.type,
			function: "get",
			params: toParams(inputs)
		};

		return new HewSyncQuery<V, V | undefined>(config, query, target ?? Create);
	}

	export function create<V extends HewSyncType>(
		type: typeof HewSyncType,
		method: string,
		inputs: { [K: string]: any }
	) {
		let config = getFullTypeData(type);

		let query = {
			type: config.type.type,
			function: method,
			params: toParams(inputs)
		};

		return new HewSyncQuery<V>(config, query, CreateWithCache);
	}

	export function request<V extends HewSyncType, K = V>(
		type: typeof HewSyncType,
		method: string,
		inputs: { [K: string]: any },
		target?: V
	) {
		let config = getFullTypeData(type);

		let query = {
			type: config.type.type,
			function: method,
			params: toParams(inputs)
		};

		return new HewSyncQuery<V, K>(config, query, target);
	}

	export function list<V extends HewSyncType>(type: typeof HewSyncType, inputs: { [K: string]: any }) {
		let config = getFullTypeData(type);

		let query = {
			type: config.type.type,
			function: "list",
			params: toParams(inputs)
		};

		return new HewSyncQuery<
			V,
			{
				items: V[];
				nextToken?: string;
			}
		>(config, query, List);
	}

	export function batchGet<V extends HewSyncType>(type: typeof HewSyncType, inputs: { [K: string]: any }) {
		let config = getFullTypeData(type);

		let query = {
			type: config.type.type,
			function: "batchGet",
			params: toParams(inputs)
		};

		return new HewSyncQuery<
			V,
			{
				items: V[];
				nextToken?: string;
			}
		>(config, query, List);
	}

	// list -> { items: T[]; nextToken?: string }
}
